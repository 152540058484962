<template>
  <div class="bold-component">
    <div class="title">
      <div class="container">
        <h4>BOLD</h4>
      </div>
    </div>
    <div>
      <div v-if="!isBoldContentsReady">
        <div v-for="(item,index) in 3" :key="index">
          <categoriesPlaceholder></categoriesPlaceholder>
        </div>
      </div>
      <div v-else-if="isBoldContentsReady && boldContents.length === 0">
        <ErrorAlert :message="'No Data Found'"></ErrorAlert>
      </div>
      <div v-else>
        <gridCategoryCard :contents="boldContents" :section="boldSection"></gridCategoryCard>
        <!-- <grid :section="boldSection" :contents="boldContents"></grid> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  name: "Bold",
  props: {
    menuDetail: {
      type: Object
    }
  },
  data() {
    return {
      boldContents: [],
      boldSection: null,
      isBoldContentsReady: false,
      responseError: null
    };
  },
  computed: {
    ...mapGetters(["appConfig"])
  },
  created() {
    // this.loadBoldContents(this.menuDetail);
    if (this.menuDetail === undefined) {
      let index = this.appConfig.menu.findIndex(element => {
        return element.id === "BOLD";
      });
      this.loadBoldContents(this.appConfig.menu[index]);
    } else {
      this.loadBoldContents(this.menuDetail);
    }
  },
  mounted() {
    eventBus.$on("bold-contents", response => {
      console.log("response bold", response);
      this.isBoldContentsReady = true;
      if (!response.reason) {
        this.boldContents = response.data;
      } else if (response.errorcode === 7109) {
        this.boldContents = [];
        this.responseError = response.reason;
      }
    });
  },
  methods: {
    loadBoldContents(menu) {
      this.isBoldContentsReady = false;

      if (menu) {
        let index = this.appConfig.screens.findIndex(element => {
          return menu.id === element.id;
        });
        if (index > -1) {
          this.boldSection = this.appConfig.screens[index].sections[0];
        }

        let payload = {
          endpoint: this.boldSection.endpoint,
          type: "bold",
          params: this.boldSection.parameters
        };

        eventBus.$emit("category-list", payload);
      }
    },
    lightUp(payload) {
      console.log("payload from lightUp", payload);
    }
  },
  components: {
    categoriesPlaceholder: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"
      ),
    grid: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/grid.vue"
      ),
    gridCategoryCard: () =>
      import(
        /* webpackChunkName: "categorycard" */ "@/components/Templates/gridCategoryCards.vue"
      ),
    ErrorAlert: () =>
      import(
        /* webpackChunkName: "erroralert" */ "@/components/Templates/ErrorAlert.vue"
      ),
  },
  beforeDestroy() {
    eventBus.$off("bold-contents");
  }
};
</script>

<style lang='scss' scoped>
@import "@/sass/_variables.scss";
.bold-component {
  .title {
    background: linear-gradient(90deg, #1d1d1d 0%, #1a1a1a 100%);
    padding: 10px;
    h4 {
      opacity: 1;
      color: #efeff4;
      font-family: $font-regular;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
    }
  }
}
</style>